import { render, staticRenderFns } from "./filterSearch.vue?vue&type=template&id=f224e972&scoped=true&"
import script from "./filterSearch.vue?vue&type=script&lang=js&"
export * from "./filterSearch.vue?vue&type=script&lang=js&"
import style0 from "./filterSearch.vue?vue&type=style&index=0&id=f224e972&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f224e972",
  null
  
)

export default component.exports