import { render, staticRenderFns } from "./videoComment.vue?vue&type=template&id=f99dbcb6&scoped=true&"
import script from "./videoComment.vue?vue&type=script&lang=js&"
export * from "./videoComment.vue?vue&type=script&lang=js&"
import style0 from "./videoComment.vue?vue&type=style&index=0&id=f99dbcb6&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f99dbcb6",
  null
  
)

export default component.exports